import { useTracking } from '@change/core/react/tracking';

import { usePetitionDetailsPageData, usePetitionDetailsPageFcm } from 'src/app/pages/petitionGamma/details/pageContext';
import type { DecisionMaker } from 'src/app/pages/petitionGamma/details/shared/types';

import { useDecisionMakerCardsModal } from '../components/DecisionMakerCard/components/DecisionMakerCardsModal';

export type AllDmResponses = ReadonlyArray<{
	readonly id: string;
	readonly decisionMaker: { readonly id: string; readonly displayName: string };
}>;

type Result = ModelHookResult<
	{
		displayedDM?: DecisionMaker;
		displayShowAllButton: boolean;
		allDmResponses: AllDmResponses;
		shouldTrackDmEmailCtaView: boolean;
		numberOfDms: number;
	},
	{
		handleViewMoreClick: () => void;
	}
>;

export function useDecisionMakerCards(): Result {
	const pageData = usePetitionDetailsPageData();
	const fcm = usePetitionDetailsPageFcm();
	const openDmListModal = useDecisionMakerCardsModal();
	const track = useTracking();

	const {
		data: {
			petition: {
				primaryDecisionMakerConnection: { nodes: decisionMakers, totalCount: numberOfDms },
				dmResponsesConnection,
			},
		},
	} = pageData;

	const sortedDMs = [...decisionMakers]
		// Sort DMs so that publicly visible (i.e. have links to their profile page) are at the top
		.sort(function visibleAtTop(x, y) {
			if (x.publiclyVisible === y.publiclyVisible) return 0;
			if (x.publiclyVisible) return -1;
			return 1;
		});

	// first dm with an email, if no emails, just first DM
	const displayedDM =
		sortedDMs.find((dm) => dm.contactInfo?.email) || (sortedDMs.length > 0 ? sortedDMs[0] : undefined);

	const handleViewMoreClick = () => {
		void track('petition_gamma_decision_makers_view_more_btn_click');
		openDmListModal({ pageData, fcm });
	};

	return {
		data: {
			displayedDM,
			displayShowAllButton: numberOfDms > 1,
			allDmResponses: dmResponsesConnection.nodes,
			shouldTrackDmEmailCtaView: !!displayedDM?.contactInfo?.email,
			numberOfDms,
		},
		actions: {
			handleViewMoreClick,
		},
	};
}
